import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassicCarRoutingModule } from './classic-car-routing.module';
import { ClassicCarComponent } from './pages/quote/classic-car.component';
import { ClassicCarResultsComponent } from './pages/results/classic-car-results/classic-car-results.component';
import { CoreModule } from 'src/app/core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMaskModule } from "ngx-mask"

@NgModule({
  declarations: [
    ClassicCarComponent,
    ClassicCarResultsComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    ClassicCarRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    NgxMaskModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ClassicCarModule { }

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, Observable } from 'rxjs';
import { ISectionsResponse } from '../interfaces/section.interface';

@Injectable({
  providedIn: 'root'
})
export class SectionsService {

  private headers: any;

  constructor(
    private http: HttpClient,
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json')
  }

  public get() {
    return this.http.get<ISectionsResponse>(`${environment.apiUrl}models/sections`, { headers: this.headers }).pipe(map(response => {
      return response.data;
    }));;
  }


}

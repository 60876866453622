<div class="card__quoteresultgarage--container">
    <div>
        <h2>Cobertura de garage</h2>
        <h1>${{value}}</h1>
        <span>/mes</span>
    </div>
    <div>
        <p>Cobertura obligatoria para cubrir su vehículo mientras no se usa.</p>
    </div>
    <div>
        <app-item-result text="Robo e Incendio en garage"></app-item-result>
        <app-item-result text="Asistencia 24 horas"></app-item-result>
    </div>
</div>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, Observable } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';

import { dolarAndMIAResponse } from '../interfaces/dolarAndMIA.interface';
import { QuoteResponse } from '../../motorcycle/interfaces/quoteResponse.interface';

@Injectable({
    providedIn: 'root'
})

export class ClassicCarService {
    private headers: HttpHeaders;

    constructor(
        private http: HttpClient,
        private storageService: StorageService
    ) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json')
    }


    public getMaxValueCarQuote(): Observable<number> {
        return this.http.get<dolarAndMIAResponse>(`${environment.apiUrl}rates/dolar`, { headers: this.headers }).pipe(map(response => response.data.maxInssuredAmount))
    }

    public getQuoteResults(quote: any) {
        return this.http.post<QuoteResponse>(`${environment.apiUrl}quotes`, quote, { headers: this.headers }).pipe(map(response => ({
            on_off: response.data.on_off,
            garage: response.data.garage
        })));
    }

    public getZone(cp: string) {
		return this.http.get(`${environment.apiUrl}zones?postal_code=${cp}`, { headers: this.headers });
	}
}
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MotorcycleRoutingModule } from './motorcycle-routing.module';
import { MotorcycleComponent } from './pages/quote/motorcycle.component';
import { CoreModule } from 'src/app/core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MotorcycleResultsComponent } from './pages/results/motorcycle-results/motorcycle-results.component';


@NgModule({
  declarations: [
    MotorcycleComponent,
    MotorcycleResultsComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule,
    MotorcycleRoutingModule,
    AutocompleteLibModule,
    HttpClientModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MotorcycleModule { }

<div class="card__quoteresult--container">
  <span *ngIf="quote.recommended" class="card__quoteresult-recommended"
    >RECOMENDADO</span
  >
  <h2>Cobertura {{ quote.coverage }}</h2>
  <h1>${{ quote.premio }}</h1>
  <span>por día activo</span>
  <p *ngIf="quote.coverage == 'A'">
    Cobertura On-Off por si sólo necesitás cobertura de responsabilidad civil.
  </p>
  <p *ngIf="quote.coverage == 'B'">
    Cobertura On-Off para quienes quieran proteger su vehículo.
  </p>
  <p *ngIf="quote.coverage == 'B1'">
    Cobertura On-Off para quienes quieran una protección máxima de su vehículo.
  </p>
  <app-item-result text="Responsabilidad civil"></app-item-result>
  <app-item-result text="Asistencia 24 horas"></app-item-result>
  <app-item-result
    text="Incendio total"
    *ngIf="quote.coverage == 'B' || quote.coverage == 'B1'"
  ></app-item-result>
  <app-item-result
    text="Robo o hurto total"
    *ngIf="quote.coverage == 'B' || quote.coverage == 'B1'"
  ></app-item-result>
  <app-item-result
    text="Accidente total"
    *ngIf="quote.coverage == 'B'"
  ></app-item-result>
  <app-item-result
    text="Carreras de regularidad"
    *ngIf="section === 'classic-car'"
  ></app-item-result>
</div>

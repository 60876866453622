import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  item(item: {}){
    const json = JSON.stringify(item);
    localStorage.setItem('quoteResults', json);
  }

  setInsurable(item: {}){
    const json = JSON.stringify(item);
    localStorage.setItem('insurable', json);
  }

  get quote(){
    const quoteResults = (localStorage.getItem('quoteResults')) || '';
    return  JSON.parse(quoteResults);
  }
  
  get insurable(){
    const quoteResults = (localStorage.getItem('insurable')) || '';
    return  JSON.parse(quoteResults);
  }

  clear(){
    localStorage.clear();
  }
}
<app-header></app-header>
<div class="container container__quote">
  <form [formGroup]="motoForm" (ngSubmit)="onSubmit(motoForm)" novalidate>
    <app-quote-title title="Cotizá tu moto de alta gama"></app-quote-title>

    <div *ngIf="!insurable.insuredAmount" class="motorcycle__quoter__info">
      <app-quote-subtitle subtitle="Empecemos con los datos de la moto"></app-quote-subtitle>

      <div class="col-md-7">
        <app-quote-label-form text="Marca"></app-quote-label-form>
        <div class="ng-autocomplete">
          <ng-autocomplete
            [ngClass]="{
              'is-invalid': isSubmited && motoForm.get('brand')?.errors
            }"
            required
            [data]="brands"
            [searchKeyword]="searchKeywordBrand"
            placeholder="Marca"
            (selected)="getModels($event)"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
            formControlName="brand"
            class="form-control autocomplete-control"
          >
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.name"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFoundText"></div>
          </ng-template>
        </div>
      </div>
      <div class="col-md-7">
        <app-quote-label-form text="Modelo *"></app-quote-label-form>

        <div class="ng-autocomplete">
          <ng-autocomplete
            [ngClass]="{
              'is-invalid': isSubmited && motoForm.get('model')?.errors
            }"
            required
            [data]="models"
            [searchKeyword]="searchKeywordModel"
            placeholder="Modelo"
            (selected)="getYears($event)"
            [itemTemplate]="modelItemTemplate"
            [notFoundTemplate]="notFoundTemplateModel"
            formControlName="model"
            class="form-control autocomplete-control"
          >
          </ng-autocomplete>

          <ng-template #modelItemTemplate let-item>
            <a [innerHTML]="item.model"></a>
          </ng-template>

          <ng-template #notFoundTemplateModel let-notFound>
            <div innerHTML="notFoundText">No se encontraron resultados</div>
          </ng-template>
        </div>
        <div><small style="font-size:11px; font-weight: bold;">*Si no encontrás tu modelo es porque no lo cubrimos.</small></div>
      </div>
      <div class="col-md-5">
        <app-quote-label-form text="Año"></app-quote-label-form>
        <select
          class="form-select"
          formControlName="year"
          required
          [ngClass]="{
            'is-invalid': isSubmited && motoForm.get('year')?.errors
          }"
          (change)="getInsuredAmount()"
        >
          <option value="">Seleccione</option>
          <option value="{{ year.year }}" *ngFor="let year of years">
            {{ year.year }}
          </option>
        </select>
      </div>
    </div>

    <div *ngIf="insurable.insuredAmount" class="motorcycle__quoter__info">
      <app-quote-subtitle subtitle="Tu moto"></app-quote-subtitle>
      <app-card-insured-amount
        @fadeAnimation
        (changedQuote)="changeQuote($event)"
        img="../../../../../assets/casco.svg"
        [insurable]="insurable"
      ></app-card-insured-amount>
    </div>

    <div *ngIf="insurable.insuredAmount" @fadeAnimation>
      <app-quote-subtitle subtitle="Ahora algunos de tus datos"></app-quote-subtitle>
      <div class="motorcycle__quoter__info">
        <div class="row">
          <div class="col-sm-6">
            <app-quote-label-form text="Nombre"></app-quote-label-form>
            <input
              type="text"
              class="form-control"
              formControlName="firstname"
              required
              [ngClass]="{
                'is-invalid': isSubmited && motoForm.get('firstname')?.errors
              }"
            />
          </div>
          <div class="col-sm-6">
            <app-quote-label-form text="Apellido"></app-quote-label-form>
            <input
              type="text"
              class="form-control form-lastname"
              formControlName="lastname"
              required
              [ngClass]="{
                'is-invalid': isSubmited && motoForm.get('lastname')?.errors
              }"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <app-quote-label-form text="Código Postal"></app-quote-label-form>
            <input
              type="number"
              class="form-control"
              formControlName="cp"
              required
              (change)="getZone()"
              [ngClass]="{
                'is-invalid': isSubmited && motoForm.get('cp')?.errors
              }"
            />
          </div>
          <div class="col-sm-5">
            <app-quote-label-form text="Email"></app-quote-label-form>
            <input
              type="email"
              class="form-control"
              formControlName="email"
              required
              [ngClass]="{
                'is-invalid': isSubmited && motoForm.get('email')?.errors
              }"
            />
          </div>
        </div>
        <app-button text="Cotizar"></app-button>
      </div>
    </div>
  </form>
</div>
<app-footer></app-footer>

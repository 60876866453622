import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CotizarComponent } from './pages/cotizar/cotizar.component';
import { ClassicCarModule } from './features/classic-car/classic-car.module';
import { MotorcycleModule } from './features/motorcycle/motorcycle.module';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuadModule } from './features/quad/quad.module';

@NgModule({
  declarations: [
    AppComponent,
    CotizarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ClassicCarModule,
    MotorcycleModule,
    QuadModule,
    CoreModule,
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

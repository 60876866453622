import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isCollapsed: boolean = false;
  constructor() { }

  ngOnInit(): void {

  }

  public collapse(){
    if(this.isCollapsed){
      this.isCollapsed = false;
    }else{
      this.isCollapsed = true;
    }
  }

}

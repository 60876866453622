<div class="card mt-3">
  <div class="card-body card__insuredAmount">
    <div class="card__insuredAmount--image">
      <img src="{{ img }}" />
    </div>

    <div class="card__insuredAmount--text">
      <strong>
        {{ insurable.brand }}
        {{ insurable.model }}
        - {{ insurable.year }}
      </strong>
      <div>
        <a (click)="quoteChanged()">Modificar</a>
      </div>
    </div>

    <div class="card__insuredAmount--value">
      <div class="card__insuredAmount--price">
        Suma Asegurada
        <div>
          <strong
            >${{ insurable.insuredAmount | mask : "separator" : "." }}</strong
          >
        </div>
      </div>
    </div>
  </div>
</div>

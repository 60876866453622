<app-header></app-header>
<div class="container container__quote">
    <app-quote-title title="Cotizando tu cuatriciclo o arenero"></app-quote-title>
    <div class="motorcycle__quoter__info">
        <app-card-insured-amount [insurable]="insurable" @fadeAnimation (changedQuote)="changeQuote($event)"
            img="../../../../../../assets/icons/quad.svg"></app-card-insured-amount>
    </div>

    <app-quote-title title="Encontramos éstas opciones para vos"></app-quote-title>
    <div style="margin-top:15px;border:1px solid #ccc;padding: 15px; background-color: #fff;border-radius: 8px;font-size: 10px;display:flex; justify-items: center; align-items: center;gap:5px;">
        <div style="display: flex;justify-items: center; align-items: center;"><i class="eva eva-alert-circle-outline" style="font-size: 14px;"></i></div>
        <div style="font-size: 14px;">Para cuatriciclos y areneros la cobertura de garage es obligatoria. Sólo se cubrirán vehículos patentados y mientras circulen en vías habilitadas.</div>
    </div>
    <div class="card__result--container">
        <app-card-quote-result *ngFor="let quote of quoteResults" @fadeAnimation
            [quote]="quote"></app-card-quote-result>
    </div>
    <app-card-quote-garage-result-quad [value]="garageQuote.cuota"></app-card-quote-garage-result-quad>
    <app-appstore></app-appstore>
</div>
<app-footer></app-footer>
<script>
  gtag('event', 'conversion', {'send_to': 'AW-962231259/2WbnCNCK7ZMZENv36coD'});
</script>
<app-header></app-header>
<div class="container container__quote">
  <app-quote-title title="Cotizando tu moto"></app-quote-title>
  <div class="motorcycle__quoter__info">
    <app-card-insured-amount
      [insurable]="insurable"
      @fadeAnimation
      (changedQuote)="changeQuote($event)"
      img="../../../../../../assets/casco.svg"
    ></app-card-insured-amount>
  </div>

  <app-quote-title
    title="Encontramos éstas opciones para vos"
  ></app-quote-title>

  <div class="card__result--container">
    <app-card-quote-result
      *ngFor="let quote of quoteResults"
      @fadeAnimation
      [quote]="quote"
    ></app-card-quote-result>
  </div>
  <app-card-quote-garage-result
    [value]="garageQuote.cuota"
  ></app-card-quote-garage-result>
  <app-appstore></app-appstore>
</div>
<app-footer></app-footer>

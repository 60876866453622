import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MotorcycleComponent } from './pages/quote/motorcycle.component';
import { MotorcycleResultsComponent } from './pages/results/motorcycle-results/motorcycle-results.component';

const routes: Routes = [
  { path: '', component: MotorcycleComponent },
  { path: 'resultados', component: MotorcycleResultsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MotorcycleRoutingModule { }

import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-quote-subtitle',
    templateUrl: './quote-subtitle.component.html',
    styleUrls: ['./quote-subtitle.component.scss']
})

export class QuoteSubtitleComponent implements OnInit {
    @Input() subtitle: string = '';

    constructor () {}

    ngOnInit(): void {
        
    }
}
<section id="footer">
    <div class="container-fluid">
        <div class="container">
            <div class="row first">
                <div class="col-lg-3 col-md-12 d-flex flex-column justify-content-center align-items-center">
                    <img src="assets/brand/logo-blanco.png">
                    <p class="py-3 text-center">Copyright © <span id="year"></span> - Activar Insurtech</p>
                </div>
                <div class="col-lg-2 col-md-6">
                    <h6>MENÚ</h6>
                    <a href="https://activar.app">Inicio</a>
                    <a href="https://activar.app/#nosotros">Quiénes somos</a>
                    <a href="https://activar.app/#funciona">Cómo Funciona</a>
                    <a href="https://activar.app/#ventajas">Ventajas</a>
                </div>
                <div class="col-lg-2 col-md-6">
                    <h6>LEGALES</h6>
                    <a href="https://activar.app/Terminos-y-Condiciones.html">Términos y condiciones</a>
                    <a href="https://activar.app/Politica-de-Privacidad.html">Política de privacidad</a>
                </div>
                <div class="col-lg-2 col-md-6">
                    <h6>AYUDA</h6>
                    <a href="https://activar.app/#faqs">Preguntas frecuentes</a>
                    <a href="mailto:hola@activar.app">hola@activar.app</a>
                    <div class="row">
                        <a href="#"><i class="fab fa-facebook-square mr-4"></i></a>
                        <a href="#"><i class="fab fa-instagram"></i></a>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <h6>DESCARGA LA APP</h6>
                    <a href="https://apps.apple.com/app/activar/id1582043834" class="download" target="_blank">
                        <img src="assets/app-store-blanco.svg">	
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.activar" class="download">
                        <img src="assets/google-play-blanco.svg" target="_blank">	
                    </a>
                </div>	
            </div>
        </div>
    </div>
    <div class="container-fluid bottom">
        <div class="container">
            <div class="row last">
                <div class="col-xl-2 col-lg-2 col-md-3 col-6 px-0">
                    <p class="text-center my-4 px-3">Nº de inscripción<br>0244</p>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-6 px-0">
                    <p class="text-center my-4 px-3">Atención al asegurado<br><a href="tel:0800-666-8400" target="_blank">0800-666-8400</a></p>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-6 px-0">
                    <p class="text-center my-4 px-3">Organismo de control</p>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-6 px-0">
                    <p class="text-center my-4 px-3 bn-mob"><a href="https://www.argentina.gob.ar/superintendencia-de-seguros" target="_blank">www.argentina.gob.ar/ssn</a></p>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-12 px-0 d-flex justify-content-center align-items-center">
                    <a href="https://www.argentina.gob.ar/superintendencia-de-seguros" target="_blank">
                        <img src="assets/Logo-ssn.png">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

import { environment } from "src/environments/environment";
export function Log() {
    return (
        target: Object,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) => {
        const originalMethod = descriptor.value;

        descriptor.value = async function (...args: any[]) {
            if (!environment.production) {
                console.group(`Component: ${target.constructor.name}`);
                console.info(`Entering ${propertyKey} method`);
                console.table(this);
                console.log(`Leaving ${propertyKey} method`);
                console.groupEnd();
            }
            const result = originalMethod.apply(this, args);
            return result;
        };
        return descriptor;
    };
}

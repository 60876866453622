import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClassicCarComponent } from './pages/quote/classic-car.component';
import { ClassicCarResultsComponent } from './pages/results/classic-car-results/classic-car-results.component';

const routes: Routes = [
  { path: '', component: ClassicCarComponent },
  { path: 'resultados', component: ClassicCarResultsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClassicCarRoutingModule { }

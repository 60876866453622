import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from '../components/header/header.component';
import { FooterComponent } from '../components/footer/footer.component';
import { CardComponent } from './components/cards/card/card.component';
import { ButtonComponent } from './components/buttons/button/button.component';
import { CardQuoteResultComponent } from './components/cards/card-quote-result/card-quote-result.component';
import { ItemResultComponent } from './components/item-result/item-result.component';
import { CheckComponent } from './components/check/check.component';
import { CardQuoteGarageResultComponent } from './components/cards/card-quote-garage-result/card-quote-garage-result.component';
import { CardInsuredAmountComponent } from './components/card-insured-amount/card-insured-amount.component';
import { QuoteTitleComponent } from './components/quote-title/quote-title.component';
import { QuoteSubtitleComponent } from './components/quote-subtitle/quote-subtitle.component';
import { QuoteLabelFormComponent } from './components/quote-label-form/quote-label-form.component';
import { AppStoreComponent } from './components/appstore/appstore.component';
import { NgxMaskModule } from 'ngx-mask';
import { CardQuoteGarageResultQuadComponent } from './components/cards/card-quote-garage-result-quad/card-quote-garage-result-quad.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, CardComponent, ButtonComponent, CardQuoteResultComponent, ItemResultComponent, CheckComponent, CardQuoteGarageResultComponent, CardInsuredAmountComponent, QuoteTitleComponent, QuoteSubtitleComponent, QuoteLabelFormComponent, AppStoreComponent, CardQuoteGarageResultQuadComponent],
  imports: [
    CommonModule, RouterModule, NgxMaskModule.forRoot()
  ],
  exports: [HeaderComponent, FooterComponent, CardComponent, ButtonComponent, CardQuoteResultComponent, ItemResultComponent, CheckComponent, CardQuoteGarageResultComponent, CardInsuredAmountComponent, QuoteTitleComponent, QuoteSubtitleComponent, QuoteLabelFormComponent, AppStoreComponent, CardQuoteGarageResultQuadComponent]
})
export class CoreModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CotizarComponent } from './pages/cotizar/cotizar.component';


const routes: Routes = [
	{
		path: "",
		children: [
			{
				path: "", component: CotizarComponent,
			}
		],
	},
	
	{ path: 'cotizar/autos-clasicos', loadChildren: () => import('./features/classic-car/classic-car.module').then(m => m.ClassicCarModule) },
	{ path: 'cotizar/motos-alta-gama', loadChildren: () => import('./features/motorcycle/motorcycle.module').then(m => m.MotorcycleModule) },
	{ path: 'cotizar/cuatriciclos-y-areneros', loadChildren: () => import('./features/quad/quad.module').then(m => m.QuadModule) },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})


export class AppRoutingModule { }

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-quote-result',
  templateUrl: './card-quote-result.component.html',
  styleUrls: ['./card-quote-result.component.scss']
})
export class CardQuoteResultComponent implements OnInit {
  @Input() quote: any;
  @Input() section!: string;
  status: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  setCoverage(){
    this.status = !this.status;
    console.log(this.status);
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, Observable } from 'rxjs';
import { Insurable } from '../interfaces/insurable.interface';
import { InsuredAmountResponse } from '../interfaces/insuredAmountResponse.interface';
import { Brand, IMotoBrandResponse, IMotoModelResponse, IMotoYearsResponse } from '../interfaces/brand.interface';
import { Model } from '../interfaces/model.interface';
import { Quote } from '../interfaces/quote.interface';
import { QuoteResponse } from '../interfaces/quoteResponse.interface'

@Injectable({
	providedIn: 'root'
})
export class MotorcycleService {

	private headers: any;

	constructor(
		private http: HttpClient,
	) {
		this.headers = new HttpHeaders();
		this.headers = this.headers.set('Content-Type', 'application/json')
	}

	public getBrands(sectionId: string){
		return this.http.get<IMotoBrandResponse>(`${environment.apiUrl}models/brand/${sectionId}`, { headers: this.headers }).pipe(map(response => {
            return response.data;
        }));;
	}

	public getModels(brand: string, sectionId: string){
		return this.http.get<IMotoModelResponse>(`${environment.apiUrl}models/${brand}/models/${sectionId}`, { headers: this.headers }).pipe(map(response => {
            return response.data;
        }));;
	}

	getYears(code: string) {
        return this.http.get<IMotoYearsResponse>(`${environment.apiUrl}models/${code}/years`, {}).pipe(map(response => {
            return response.data.years;
        }));
    }

	public getInsuredAmount(moto: Insurable): Observable<any> {
		return this.http.get<InsuredAmountResponse>(`${environment.apiUrl}quotes/models?brand=${moto.brand}&code=${moto.code}&year=${moto.year}&is0km=${moto.is0km}`, { headers: this.headers }).pipe(map(response => ({
			value: response.data[0].price
		})));
	}

	public getZone(cp: string) {
		return this.http.get(`${environment.apiUrl}zones?postal_code=${cp}`, { headers: this.headers });
	}
	
	public getQuoteResults(quote: Quote) {
		return this.http.post<QuoteResponse>(`${environment.apiUrl}quotes`, quote, { headers: this.headers }).pipe(map(response => ({
			on_off: response.data.on_off,
			garage: response.data.garage
		})));
	}
}

import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FadeAnimation } from 'src/app/core/animations/fade.animation';
import { StorageService } from 'src/app/core/services/storage.service';
import { ClassicCarService } from '../../services/classic-car.service';

@Component({
  selector: 'app-classic-car',
  templateUrl: './classic-car.component.html',
  styleUrls: ['./classic-car.component.scss'],
  animations: [
    FadeAnimation
  ],
})
export class ClassicCarComponent implements OnInit {

  insurable = {
    brand: '',
    model: '',
    year: 0,
    is0km: false,
    insuredAmount: ''
  }
  incorrectCPmessage: string = '';
  zoneId: string = '';
  maxInssuredAmount!: number
  maxInssuredAmountNumber!: number;
  years: number[] = []

  carForm = new FormGroup({
    brand: new FormControl('', Validators.required),
    model: new FormControl('', Validators.required),
    year: new FormControl('', Validators.required),
    insuredAmount: new FormControl('', Validators.required),
  })
  userInfo = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    cp: new FormControl('', Validators.required),
  })

  isSubmited: boolean = false;
  insuredInput: string = '';

  constructor(
    private classicCarService: ClassicCarService,
    private storageService: StorageService,
    private router: Router
  ) { }

  stringToNumber(stringText: string) {
    //stringText = stringText.substring(1);
    stringText = stringText.replace('.', '');
    stringText = stringText.replace('.', '');
    return parseInt(stringText);
  }
  ngOnInit(): void {
    //this.getMaxValueQuote()

    let currentTime = new Date().getFullYear();
    let maxYear = currentTime - 25;
    let minYear = 1940;
    for (let i = minYear; i <= maxYear; i++) {
      this.years.push(i);
    }
  }

  getMaxValueQuote() {
    this.classicCarService.getMaxValueCarQuote().subscribe(response => {
      this.maxInssuredAmount = response;
      console.log(this.stringToNumber(response.toString()));
      this.maxInssuredAmountNumber = this.stringToNumber(response.toString());
      this.carForm.get('insuredAmount')!.setValidators(Validators.max(this.maxInssuredAmountNumber));
      this.carForm.get('insuredAmount')!.updateValueAndValidity();
    })
  }

  getZone() {
    const postalCode = this.userInfo.value.cp;
    this.classicCarService.getZone(postalCode).subscribe((response: any) => {
      if (response['data'].length > 0) {
        this.incorrectCPmessage = '';
        this.zoneId = response['data'][0].id;

      } else {
        this.incorrectCPmessage = 'Código Postal incorrecto.'
      }

    });
  }

  getCardInsured(form: FormGroup) {
    this.isSubmited = true;
    /*
    if (this.carForm.value.insuredAmount > this.maxInssuredAmount) {
      this.insuredInput = 'La suma ingresada debe ser menor o igual a $'
    }*/

    if (this.carForm.valid) {
      this.insurable.brand = form.value.brand
      this.insurable.model = form.value.model;
      this.insurable.year = form.value.year;
      this.insurable.insuredAmount = form.value.insuredAmount
      this.isSubmited = false
      this.insuredInput = ''
      this.storageService.setInsurable(this.insurable)
    }

  }

  onSubmit(form: FormGroup) {
    this.isSubmited = true;
    const quote = {
      brand: this.insurable.brand,
      value: this.insurable.insuredAmount,
      model: this.insurable.model,
      client_email: form.value.email,
      client_first_name: form.value.firstname,
      client_last_name: form.value.lastname,
      section: 'classic_car',
      year: parseInt(this.carForm.value.year),
      is0km: false,
      zone_id: this.zoneId,
      channel: 'cotizador'
    }


    if (this.carForm.valid && this.userInfo.valid) {
      this.classicCarService.getQuoteResults(quote).subscribe(response => {
        this.storageService.item(response);
        this.router.navigateByUrl('cotizar/autos-clasicos/resultados')

      })
    }
  }


  changeQuote(newValue: string) {
    this.insurable.insuredAmount = newValue;
    this.router.navigateByUrl('cotizar/autos-clasicos')
  }
}

import { Component, OnInit } from "@angular/core"
import { FadeAnimation } from "src/app/core/animations/fade.animation"
import { StorageService } from "src/app/core/services/storage.service"
import { QuoteGarageResponse } from "src/app/features/motorcycle/interfaces/quoteGarageResponse.interface"
import { Router } from "@angular/router"

@Component({
    selector: 'app-classic-car-results',
    templateUrl: './classic-car-results.component.html',
    styleUrls: ['./classic-car-results.component.scss'],
    animations: [FadeAnimation]
})

export class ClassicCarResultsComponent implements OnInit {
    quoteResults: [] = [];
    insurable: any;
    garageQuote: QuoteGarageResponse = { premio: '', prima: '', cuota: '', prima_mensual: '' };
    section: string = 'classic-car'

    constructor(private storageService: StorageService, private router: Router) { }

    ngOnInit(): void {
        this.quoteResults = this.storageService.quote.on_off;
        this.garageQuote = this.storageService.quote.garage;
        this.insurable = this.storageService.insurable; 
        console.log(this.storageService.quote)
        console.log(this.storageService.insurable)
    }

    changeQuote(newValue: string) {
        this.insurable.insuredAmount = newValue;
        this.router.navigateByUrl('cotizar/autos-clasicos')
    }
}
<app-header></app-header>
<h1>sadasd</h1>
<div
  class="container container__quoter"
  style="margin-top: 150px; margin-bottom: 200px"
>
  <app-quote-title title="¿Qué seguro estás buscando?"></app-quote-title>

  <div class="container__quoter--box">
    <div *ngFor="let section of sections">
      <div class="container__quoter--image" (click)="quote(section.name)">
        <img src="../../../assets/icons/{{section.name}}.svg" />
        <span>{{section.description}}</span>
      </div>
    </div>
   

  </div>
</div>

<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FadeAnimation } from 'src/app/core/animations/fade.animation';
import { Log } from 'src/app/core/decorators/log.decorator';
import { StorageService } from 'src/app/core/services/storage.service';
import { Brand, IMotoBrand, IMotoModel, IYear, IYearValue } from '../../interfaces/brand.interface';
import { Insurable } from '../../interfaces/insurable.interface';
import { Model } from '../../interfaces/model.interface';
import { Quote } from '../../interfaces/quote.interface';
import { MotorcycleService } from '../../services/motorcycle.service';

@Component({
  selector: 'app-motorcycle',
  templateUrl: './motorcycle.component.html',
  styleUrls: ['./motorcycle.component.scss'],
  animations: [
    FadeAnimation
  ]
})

export class MotorcycleComponent implements OnInit {

  brands: IMotoBrand[] = [];
  models: IMotoModel[] = [];
  years: IYearValue[] = [];
  incorrectCPmessage: string = '';
  insurable: Insurable = {
    brand: '',
    model: '',
    year: 0,
    is0km: false,
    code: '',
    insuredAmount: ''
  }
  zoneId: string = '';
  notFoundText: string = 'No se encontraron resultados';
  searchKeywordModel: string = 'model';
  searchKeywordBrand: string = 'name';
  isSubmited: boolean = false;
  motoForm = new FormGroup({
    brand: new FormControl('', Validators.required),
    model: new FormControl('', Validators.required),
    year: new FormControl('', Validators.required),
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    cp: new FormControl('', Validators.required),
  })
  constructor(
    private motorcycleService: MotorcycleService,
    private storageService: StorageService,
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.getBrands();
  }

  getBrands() {
		this.motorcycleService.getBrands('1').subscribe({
			next: (response) => {
				this.brands = response;
			},
			error: (error) => {
				console.log(error);
			}
		})
	}

	getModels(item: { id: string, name: string }) {
		this.motorcycleService.getModels(item.id, '1').subscribe({
			next: (response) => {
				this.models = response;
			},
			error: (error) => {
				console.log(error);
			}
		})
	}

	getYears(item: { id: string, name: string, code: string }) {
		this.motorcycleService.getYears(item.code).subscribe({
			next: (response) => {
				this.years = response;
			},
			error: (error) => {
				console.log(error);
			}
		})
	}



  getInsuredAmount() {
    //const date = new Date().getFullYear();
    //this.show0km = (this.step1.year == date || this.step1.year == (date - 1)) ? true : false;

    this.insurable.brand = this.motoForm.value.brand.name;
    this.insurable.code = this.motoForm.value.model.code;
    this.insurable.model = this.motoForm.value.model.model;
    this.insurable.year = this.motoForm.value.year;

    this.insurable.insuredAmount = this.find(this.motoForm.controls['year']?.value, this.years);
    this.storageService.setInsurable(this.insurable);
    /*this.motorcycleService.getInsuredAmount(this.insurable).subscribe(response => {
      this.insurable.insuredAmount = response.value;
      this.storageService.setInsurable(this.insurable);
    });*/
  }

 /* selectBrand(item: { id: string, name: string }) {
    this.getModels(item.id);
  }

  selectModel(item: any) {
    this.getYears(item.code);
  }*/

  private find(nameKey: string, myArray: any) {
		for (let i = 0; i < myArray.length; i++) {
			if (myArray[i].year === nameKey) {
				return myArray[i].value;
			}
		}
	}

	private findModel(nameKey: string, myArray: any) {
		for (let i = 0; i < myArray.length; i++) {
			if (myArray[i].code === nameKey) {
				return myArray[i];
			}
		}
	}

	private findBrand(nameKey: number, myArray: any) {
		for (let i = 0; i < myArray.length; i++) {
			if (myArray[i].id == nameKey) {
				return myArray[i];
			}
		}
	}
  
  @Log()
  onSubmit(form: FormGroup) {
    this.isSubmited = true;
    const quote: Quote = {
      brand: this.motoForm.value.brand.name,
      code: this.motoForm.value.model.code,
      value: this.insurable.insuredAmount || '',
      client_email: this.motoForm.value.email,
      client_first_name: this.motoForm.value.firstname,
      client_last_name: this.motoForm.value.lastname,
      section: 'moto',
      year: parseInt(this.motoForm.value.year),
      is0km: false,
      zone_id: this.zoneId,
      channel: 'cotizador'
    }

    if (this.motoForm.valid) {
      this.motorcycleService.getQuoteResults(quote).subscribe(response => {       
        this.storageService.item(response);
        this.router.navigateByUrl('cotizar/motos-alta-gama/resultados');
      });
    }
  }

  changeQuote(newValue: string) {
    this.insurable.insuredAmount = newValue;
    this.insurable.insuredAmount = newValue;
  }

  getZone() {
    const postalCode = this.motoForm.value.cp;
    this.motorcycleService.getZone(postalCode).subscribe((response: any) => {
      if (response['data'].length > 0) {
        this.incorrectCPmessage = '';
        this.zoneId = response['data'][0].id;

      } else {
        this.incorrectCPmessage = 'Código Postal incorrecto.'
      }

    });
  }

}

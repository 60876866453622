<div class="card__appstore--container">
  <div>
    <h2>Descargá la app y contratá tu seguro</h2>
  </div>
  <div class="card__appstore--links">
    <a href="https://play.google.com/store/apps/details?id=com.activar"
      ><img src="../../../../assets/google-play-blanco.svg"
    /></a>
    <a href="https://apps.apple.com/app/activar/id1582043834"
      ><img src="../../../../assets/app-store-blanco.svg"
    /></a>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ISection } from 'src/app/core/interfaces/section.interface';
import { SectionsService } from 'src/app/core/services/sections.service';


@Component({
  selector: 'app-cotizar',
  templateUrl: './cotizar.component.html',
  styleUrls: ['./cotizar.component.scss']
})
export class CotizarComponent implements OnInit {
  sections: ISection[] = [];
  constructor(
    private sectionsService: SectionsService,
    private router: Router
  ) { }



  ngOnInit() {
    this.getSections();
  }

  getSections() {
    this.sectionsService.get().subscribe({
      next: response => {
        this.sections = response;
      },
      error: error => {
        console.log(error);
      }
    });
  }

  quote(name: string) {
    let url: string = '';
    switch (name) {
      case 'moto':
        url = 'motos-alta-gama';
        break;
      case 'classic_car':
        url = 'autos-clasicos';
        break;
      case 'quad':
        url = 'cuatriciclos-y-areneros';
        break;
      default:
        break;
    }
    this.router.navigate(['/cotizar/', url]);
  }


}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuadQuoteComponent } from './pages/quote/quad-quote/quad-quote.component';
import { QuadResultsComponent } from './pages/results/quad-results/quad-results.component';

const routes: Routes = [
  { path: '', component: QuadQuoteComponent },
  { path: 'resultados', component: QuadResultsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuadRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FadeAnimation } from 'src/app/core/animations/fade.animation';
import { StorageService } from 'src/app/core/services/storage.service';
import { QuoteGarageResponse } from 'src/app/features/motorcycle/interfaces/quoteGarageResponse.interface';

@Component({
  selector: 'app-quad-results',
  templateUrl: './quad-results.component.html',
  styleUrls: ['./quad-results.component.scss'],
  animations: [FadeAnimation]
})
export class QuadResultsComponent implements OnInit {

  quoteResults: [] = [];
  insurable: any;
  garageQuote: QuoteGarageResponse = { premio: '', prima: '', cuota: '', prima_mensual: '' };
  constructor(
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.quoteResults = this.storageService.quote.on_off;
    this.garageQuote = this.storageService.quote.garage;
    this.insurable = this.storageService.insurable;
    console.log(this.storageService.quote)
    console.log(this.storageService.insurable)
  }
  changeQuote(newValue: string) {
    this.insurable.insuredAmount = newValue;
    this.router.navigateByUrl('moto')
  }

}

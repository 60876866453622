<nav class="navbar navbar-expand-lg navbar-light fixed-top expand">
	<div class="container">
		<a class="navbar-brand" href="https://activar.app">
			<img src="assets/brand/logo-azul.png" class="blue">
			<img src="assets/brand/logo-blanco.png" class="white">
		</a>
		<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="collapse()">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="navbar-collapse" id="navbarNav" [ngClass]="isCollapsed ? 'collapsed' : 'collapse'">
			<ul class="navbar-nav me-auto mb-2 mb-lg-0">
				<li class="nav-item active">
					<a class="nav-link" href="https://activar.app/#nosotros">NOSOTROS</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="https://activar.app/#funciona">CÓMO FUNCIONA</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="https://activar.app/#ventajas">VENTAJAS</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="https://activar.app/#faqs">PREGUNTAS FRECUENTES</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="https://activar.app/auth/login">PRODUCTORES</a>
				</li>
				<li>
					<a class="navbar-brand button" style="padding: 0 5px" href="https://productores.activar.app/auth/login">Acceso Productores</a>
				</li>
			</ul>
			
		</div>
	</div>
</nav>
<!--
<nav class="navbar navbar-expand-lg navbar-light fixed-top expand">
	<a class="navbar-brand" href="https://activar.app">
		<img src="assets/brand/logo-azul.png" class="blue">
		<img src="assets/brand/logo-blanco.png" class="white">
	</a>
	<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
		aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
		<i class="fas fa-bars"></i>
	</button>
	<div class="collapse navbar-collapse" id="navbarNav">
		<ul class="navbar-nav mx-5">
			<li class="nav-item active">
				<a class="nav-link" href="https://activar.app/#nosotros">NOSOTROS<span
						class="sr-only">(current)</span></a>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="https://activar.app/#funciona">CÓMO FUNCIONA</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="https://activar.app/#ventajas">VENTAJAS</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="https://activar.app/#faqs">PREGUNTAS FRECUENTES</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" href="https://activar.app/auth/login">PRODUCTORES</a>
			</li>
			<li>
				<a class="navbar-brand button" href="https://productores.activar.app/auth/login">INGRESAR</a>
			</li>
		</ul>
	</div>
</nav>
-->

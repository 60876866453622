import {
    animate,
    style,
    transition,
    trigger,
} from '@angular/animations';

export const FadeAnimation =
    trigger('fadeAnimation', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('1000ms', style({ opacity: 1 })),
        ]),
        transition(':leave', [
            animate('0ms', style({ opacity: 0 }))
        ])
    ])
import { Component, OnInit } from '@angular/core';
import { FadeAnimation } from 'src/app/core/animations/fade.animation';
import { Log } from 'src/app/core/decorators/log.decorator';
import { StorageService } from 'src/app/core/services/storage.service';
import { QuoteGarageResponse } from '../../../interfaces/quoteGarageResponse.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-motorcycle-results',
  templateUrl: './motorcycle-results.component.html',
  styleUrls: ['./motorcycle-results.component.scss'],
  animations: [FadeAnimation]
})
export class MotorcycleResultsComponent implements OnInit {

  quoteResults: [] = [];
  insurable: any;
  garageQuote: QuoteGarageResponse = { premio: '', prima: '', cuota: '', prima_mensual: '' };
  constructor(
    private storageService: StorageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.quoteResults = this.storageService.quote.on_off;
    this.garageQuote = this.storageService.quote.garage;
    this.insurable = this.storageService.insurable;
  }
  changeQuote(newValue: string) {
    this.insurable.insuredAmount = newValue;
    this.router.navigateByUrl('cotizar/motos-alta-gama')
  }
}

<app-header></app-header>

<div class="container container__quote">
  <app-quote-title title="Cotizando tu auto clásico"></app-quote-title>
  <div class="classicCar__quoter__info">
    <app-card-insured-amount
      [insurable]="insurable"
      (changedQuote)="changeQuote($event)"
      @fadeAnimation
      img="../../../../../../assets/volante.svg"
    ></app-card-insured-amount>
  </div>

  <app-quote-title
    title="Encontramos éstas opciones para vos"
  ></app-quote-title>

  <div class="card__result--container">
    <app-card-quote-result
      *ngFor="let quote of quoteResults"
      @fadeAnimation
      [quote]="quote"
      [section]="section"
    ></app-card-quote-result>
  </div>
  <app-card-quote-garage-result
    @fadeAnimation
    [value]="garageQuote.cuota"
  ></app-card-quote-garage-result>
  <app-appstore @fadeAnimation></app-appstore>
</div>
<app-footer></app-footer>

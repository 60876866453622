import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuadRoutingModule } from './quad-routing.module';;
import { QuadResultsComponent } from './pages/results/quad-results/quad-results.component';
import { QuadQuoteComponent } from './pages/quote/quad-quote/quad-quote.component';
import { CoreModule } from 'src/app/core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';


@NgModule({
  declarations: [
    QuadResultsComponent,
    QuadQuoteComponent
  ],
  imports: [
    CommonModule,
    QuadRoutingModule,
    CoreModule,
    FormsModule,
    AutocompleteLibModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class QuadModule { }

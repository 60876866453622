<app-header></app-header>
<div class="container container__quote">
  <form [formGroup]="carForm" (ngSubmit)="getCardInsured(carForm)" novalidate>
    <app-quote-title title="Cotizá tu auto clásico"></app-quote-title>

    <div *ngIf="!insurable.insuredAmount" class="classicCar__quoter__info">
      <app-quote-subtitle
        subtitle="Empecemos con los datos del auto"
      ></app-quote-subtitle>

      <div class="row">
        <div class="col-md-12">
          <app-quote-label-form text="Marca"></app-quote-label-form>
          <input
            type="text"
            class="form-control"
            formControlName="brand"
            placeholder="Marca"
            required
            [ngClass]="{
              'is-invalid': isSubmited && carForm.get('brand')?.errors
            }"
          />
        </div>

        <div class="col-md-12">
          <app-quote-label-form text="Modelo"></app-quote-label-form>
          <input
            type="text"
            placeholder="Modelo"
            class="form-control"
            formControlName="model"
            required
            [ngClass]="{
              'is-invalid': isSubmited && carForm.get('model')?.errors
            }"
          />
        </div>

        <div class="col-md-4">
          <app-quote-label-form text="Año"></app-quote-label-form>
          <select
            formControlName="year"
            required
            [ngClass]="{
              'is-invalid': isSubmited && carForm.get('year')?.errors
            }"
            class="form-select"
          >
            <option value="">Seleccione</option>
            <option value="{{ year }}" *ngFor="let year of years">
              {{ year }}
            </option>
          </select>
        </div>

        <div class="col-md-12">
          <app-quote-label-form text="Suma Asegurada"></app-quote-label-form>
          <input
            type="tel"
            class="form-control"
            formControlName="insuredAmount"
            required
            [ngClass]="{
              'is-invalid': isSubmited && carForm.get('insuredAmount')?.errors
            }"
            prefix="$ "
            mask="separator"
            thousandSeparator="."
            
          />

          <span class="classicCar__insuredAmount_error-msg" *ngIf="insuredInput"
            >{{ insuredInput
            }}{{ maxInssuredAmount | mask : "separator" : "." }}
          </span>
        </div>

        <span class="classicCar__insuredAmount__info">
          La suma asegurada será verificada por un inspector antes de la emisión
          de la póliza. En el caso de no ser la que corresponde, se propondrá
          una nueva.
          
        </span>
      </div>
      <app-button text="Continuar"></app-button>
    </div>
  </form>

  <div *ngIf="insurable.insuredAmount" class="classicCar__quoter__info">
    <app-quote-subtitle subtitle="Tu auto"></app-quote-subtitle>
    <app-card-insured-amount
      @fadeAnimation
      (changedQuote)="changeQuote($event)"
      img="../../../../../assets/volante.svg"
      [insurable]="insurable"
    ></app-card-insured-amount>
  </div>

  <div *ngIf="insurable.insuredAmount">
    <app-quote-subtitle
      subtitle="Ahora algunos de tus datos"
    ></app-quote-subtitle>
    <div class="classicCar__quoter__info">
      <form [formGroup]="userInfo" (ngSubmit)="onSubmit(userInfo)" novalidate>
        <div class="row">
          <div class="col-sm-6">
            <app-quote-label-form text="Nombre"></app-quote-label-form>
            <input
              type="text"
              formControlName="firstname"
              class="form-control"
              required
              [ngClass]="{
                'is-invalid': isSubmited && userInfo.get('firstname')?.errors
              }"
            />
          </div>
          <div class="col-sm-6">
            <app-quote-label-form text="Apellido"></app-quote-label-form>
            <input
              type="text"
              class="form-control form-lastname"
              formControlName="lastname"
              [ngClass]="{
                'is-invalid': isSubmited && userInfo.get('lastname')?.errors
              }"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <app-quote-label-form text="Código Postal"></app-quote-label-form>
            <input
              type="number"
              class="form-control"
              formControlName="cp"
              (change)="getZone()"
              [ngClass]="{
                'is-invalid': isSubmited && userInfo.get('cp')?.errors
              }"
              required
            />
          </div>
          <div class="col-sm-8">
            <app-quote-label-form text="Email"></app-quote-label-form>
            <input
              type="text"
              class="form-control"
              formControlName="email"
              [ngClass]="{
                'is-invalid': isSubmited && userInfo.get('email')?.errors
              }"
              required
            />
          </div>
        </div>
        <app-button text="Cotizar"></app-button>
      </form>
    </div>
  </div>
</div>

<app-footer></app-footer>

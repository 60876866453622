import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-appstore',
    templateUrl: './appstore.component.html',
    styleUrls: ['./appstore.component.scss']
})
export class AppStoreComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }

}
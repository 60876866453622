import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-card-insured-amount',
  templateUrl: './card-insured-amount.component.html',
  styleUrls: ['./card-insured-amount.component.scss'],
})
export class CardInsuredAmountComponent implements OnInit {
  @Input() insurable: any;
  @Input() img: string = '';
  @Output() changedQuote = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }


  quoteChanged() {
    this.changedQuote.emit('');
  }
}

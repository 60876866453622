import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-quote-label-form',
    templateUrl: './quote-label-form.component.html',
    styleUrls: ['./quote-label-form.component.scss']
})

export class QuoteLabelFormComponent implements OnInit {
    @Input() text: string = '';

    constructor() { }

    ngOnInit(): void {

    }
}
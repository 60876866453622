import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: 'app-quote-title',
    templateUrl: './quote-title.component.html',
    styleUrls: ['./quote.title.component.scss']
})

export class QuoteTitleComponent implements OnInit {
    @Input() title: string = '';

    constructor() { }

    ngOnInit(): void {

    }
} 